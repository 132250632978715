import React from 'react';
import bemCn from 'bem-cn';
import SvgLoading from 'src/assets/svg/SvgLoading.svg';
import './style.scss';

const b = bemCn('preloader');

const Preloader = ({ full = false, fullWindow, background, absolute, noIcon }) => {
    return (
        <div className={b({ full, 'full-window': fullWindow, background, absolute })}>
            {!noIcon && <SvgLoading className={b('icon')} />}
        </div>
    );
};

export default Preloader;
